import React from 'react'
import { motion } from "framer-motion"
import { revealInOut } from "../helpers/transitionHelper"
import urlFor from '../helpers/imageBuilder'
import BlockBody from './BlockBody';

const Post = (props) => {
    const { post, image } = props;

    return (
        <>
            <div className="post-header">
                <div>
                    <motion.h1 variants={revealInOut} className="post-title">{post.title}</motion.h1>
                    <motion.div variants={revealInOut} className="post-meta">
                        {post.authors && <p>by {post.authors.name}</p>}
                        {post.date && <p>{post.date}</p>}
                    </motion.div>
                </div>
                <motion.img variants={revealInOut} className="post-image" src={urlFor(image).width(600).url()} alt={post.title}/>
            </div>

            <motion.div variants={revealInOut} className="post-body">
                <BlockBody blocks={post._rawBody}/>
            </motion.div>
        </>
    )
}

export default Post;